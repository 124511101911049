'use client'
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination'
import { usePathname, useSearchParams } from 'next/navigation'

type PaginationProps = {
  pagination: {
    total: number
    limit: number
    offset: number
  }
}

export const PaginationBlock: React.FC<PaginationProps> = ({ pagination }) => {
  // 注意：pagination.offset の値が特殊なので注意、いわゆるoffset+要素数の値が入っている
  const currentPage = Math.ceil(pagination.offset / pagination.limit)
  const lastPage = Math.ceil(pagination.total / pagination.limit)
  const pathName = usePathname()
  const searchParams = useSearchParams()

  const showPrev = currentPage !== 1
  const showFirst = currentPage !== 1
  const showEllipsisLower = currentPage >= 3
  const showEllipsisUpper = currentPage <= lastPage - 2
  const showNext = currentPage !== lastPage
  const showLast = currentPage !== lastPage

  const pageTo = (page: number) => {
    const params = new URLSearchParams(searchParams.toString())
    params.set('page', page.toString())
    return `${pathName}?${params.toString()}`
  }

  return (
    <Pagination>
      <PaginationContent>
        {showPrev && (
          <PaginationItem>
            <PaginationPrevious href={pageTo(currentPage - 1)} />
          </PaginationItem>
        )}
        {showFirst && (
          <PaginationItem>
            <PaginationLink href={pageTo(1)}>1</PaginationLink>
          </PaginationItem>
        )}
        {showEllipsisLower && (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        )}
        {
          <PaginationItem>
            {/* 
			現在のページへのリンクは、アンカータグによるページ内移動にする
			同ページへのリンクだと、誤ってクリックしたときに、同じページの再読み込みが発生して、ユーザビリティが低下するため
			*/}
            <PaginationLink href={`#`} isActive>
              {currentPage}
            </PaginationLink>
          </PaginationItem>
        }
        {showEllipsisUpper && (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        )}
        {showLast && (
          <PaginationItem>
            <PaginationLink href={pageTo(lastPage)}>{lastPage}</PaginationLink>
          </PaginationItem>
        )}
        {showNext && (
          <PaginationItem>
            <PaginationNext href={pageTo(currentPage + 1)} />
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  )
}
